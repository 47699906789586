import React from "react";

type HandProps = {
  skulls: number;
  roses: number;
  sendMessage: (s: string) => void;
};

function Hand({ skulls, roses, sendMessage }: HandProps): JSX.Element {
  const roseCards = [];
  const skullCards = [];
  for (let i = 0; i < roses; i++) {
    roseCards.push(
      <button key={`r${i}`} onClick={() => sendMessage("rose")}>
        🌹
      </button>
    );
  }
  for (let i = 0; i < skulls; i++) {
    skullCards.push(
      <button key={`s${i}`} onClick={() => sendMessage("skull")}>
        💀
      </button>
    );
  }
  return <div className="Hand">{roseCards.concat(skullCards)}</div>;
}

export default Hand;
