import { useState } from "react";

import { useDispatch } from "react-redux";
import { connectionSlice } from "./store";

function useConnection(url: string) {
  const [connection, setConnection] = useState<WebSocket | null>(null);
  const [keepaliveInterval, setKeepaliveInterval] = useState<number>(-1);
  const dispatch = useDispatch();

  function disconnect() {
    if (connection) {
      connection.close(1000, "Client disconnected explicitly");
      window.clearInterval(keepaliveInterval);
      setKeepaliveInterval(-1);
    }
  }

  function connect() {
    const con = new WebSocket(url);
    con.onopen = () => {
      const interval = window.setInterval(() => {
        con.send("keepalive");
      }, 2000);
      setConnection(con);
      setKeepaliveInterval(interval);
      dispatch(connectionSlice.actions.registerConnection());
    };
    con.onmessage = (event) => {
      dispatch(connectionSlice.actions.receiveMessage(event.data));
    };
    con.onclose = () => {
      dispatch(connectionSlice.actions.registerDisconnect());
    };
  }

  function sendMessage(message: string) {
    if (connection) {
      connection.send(message);
    } else {
      console.error("No connection so send message on");
    }
  }

  return {
    connection,
    connect,
    disconnect,
    sendMessage,
  };
}

export { useConnection };
