import React from "react";
//import logo from './logo.svg';
import "./App.css";

import Hand from "./Hand";
import { useConnection } from "./Connection";
import ConnectionPane from "./ConnectionPane";

function App() {
  const { connect, disconnect, sendMessage } = useConnection(
    "ws://localhost:8000/socket"
  );
  return (
    <div className="App">
      <header className="App-header">
        <ConnectionPane connect={connect} disconnect={disconnect} />
        <Hand skulls={1} roses={3} sendMessage={sendMessage} />
      </header>
    </div>
  );
}

export default App;
