import { createSlice, configureStore, PayloadAction } from "@reduxjs/toolkit";

export enum ESocketState {
  CLOSED,
  OPEN,
  OPENING,
}

interface ConnectionState {
  socketState: ESocketState;
  messages: string[];
  closeReason?: string;
}

const initialState: ConnectionState = {
  socketState: ESocketState.CLOSED,
  messages: [],
};

const connectionSlice = createSlice({
  name: "connection",
  initialState: initialState,
  reducers: {
    registerConnection: (state) => {
      state.socketState = ESocketState.OPEN;
      return state;
    },
    registerDisconnect: (state) => {
      state.socketState = ESocketState.CLOSED;
      return state;
    },
    receiveMessage: (state, action: PayloadAction<string>) => {
      state.messages.push(action.payload);
      return state;
    },
  },
});

const store = configureStore({
  reducer: {
    connection: connectionSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export { store, connectionSlice };
