import React from "react";

import { useSelector } from "react-redux";
import { ESocketState, RootState } from "./store";

interface ConnectionPaneProps {
  connect: () => void;
  disconnect: () => void;
}

function ConnectionPane(props: ConnectionPaneProps): JSX.Element {
  const wsStatus = useSelector(
    (state: RootState) => state.connection.socketState
  );
  let stateString: string;
  let button: React.ReactNode;
  const { connect, disconnect } = props;
  switch (wsStatus) {
    case ESocketState.CLOSED:
      stateString = "Closed";
      button = <button onClick={() => connect()}>Connect</button>;
      break;
    case ESocketState.OPEN:
      stateString = "Open";
      button = <button onClick={() => disconnect()}>Disconnect</button>;
      break;
    default:
      stateString = "Unknown";
      button = <button disabled>Disabled</button>;
  }

  return (
    <div className="Connection">
      <span>Connection status: {stateString}</span>
      {button}
    </div>
  );
}

export default ConnectionPane;
